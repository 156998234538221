<template>
  <div class="container">
    <div class="search">
      <el-input
        placeholder="请输入标准名称至少8个字，或不用带年份的标准号"
        v-model="searchTxt"
      ></el-input>
      <span @click="getdata">查询</span>
    </div>
    <div class="query-box">
      <h3><i class="line"></i>检验标准查询</h3>
      <div class="query-list">
        <div class="query-title">
            <span v-for="(item,index) in arrList" @click="filterSearchData(item,index)" :key="item.id" :class='{bg:item.id==isactive}'>
                <img v-if="item.id==isactive" src="@/assets/icon/select.png" alt="">
                <img v-else src="@/assets/icon/cancel.png" alt="">
                {{item.name}}
            </span>
        </div>
        <div class="query-bomb">
            <div class="query-result">
                <div v-for="(item,index) in this.standardList" :key="index">
                    <div class="result-title">
                        <img src="../assets/icon/inspection-icon.png" alt="">
                        <span>{{item.standardName}}</span>
                    </div>
                    <div class="result-text">
                        <div class="result-info">
                            <div>
                                标准名称：
                                <el-tooltip class="item" effect="dark" :content="item.standardName" placement="top-start">
                                    <span>{{item.standardName}}</span>
                                </el-tooltip>
                            </div>
                            <div>
                                标准编号：
                                <span>{{item.standardNo}}</span>
                            </div>
                            <div>
                                发布日期：
                                <span>{{item.publishTime}}</span>
                            </div>
                            <div class="status-info">
                                标准状态：
                                <span v-show="item.status==1" class="status effective">有效</span>
                                <span v-show="item.status==2" class="status suspended">暂停</span>
                                <span v-show="item.status==3" class="status undo">撤销</span>
                                <span v-show="item.status==4" class="status cancellation">注销</span>
                                <span v-show="item.status==5" class="status overdue">过期</span>
                            </div>
                        </div>
                        <div class="certificate-info">
                            <el-tooltip class="item" effect="dark" :content="item.publishUnit" placement="top-start">
                            <div class="unit-info">
                                发布单位：
                                <span>{{item.publishUnit}}</span>
                            </div>
                            </el-tooltip>
                            <div>
                                实施日期：
                                <span>{{item.implementationTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="outcome" v-show="this.standardList.length<1">
          <p><span>未查到符合条件的信息</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  data() {
    return {
        arrList:[{
            name:"国家标准",
            bool:false,
            id:1
        },{
            name:"行业标准",
            bool:false,
            id:2
        }],
        isactive:null,
        searchTxt:'',
        standardList: []
    };
  },
  methods: {
    filterSearchData(item){
        localStorage.setItem('examineId',item.id)
        this.isactive = item.id;
        var urlData = "";
        if(this.searchTxt){
            urlData = "/rzgs/stands?name="+this.searchTxt+"&Type="+this.isactive;
            
        }else {
            urlData = "/rzgs/stands?Type="+this.isactive;
        }
        axios({
            method: "get",
            url:
            process.env.VUE_APP_BASE_URL + urlData
        }).then((response) => {
            if (response.data.status === 0) {
                this.standardList = response.data.data
            }
        })

    },
    getdata() {
        localStorage.setItem('examineText',this.searchTxt)
        var urlStr = "";
        if(this.isactive){
            if(this.searchTxt){
                urlStr = "/rzgs/stands?name="+this.searchTxt+"&Type="+this.isactive;
            }else {
                urlStr = "/rzgs/stands?Type="+this.isactive;
            }
        }else if(this.searchTxt){
            if(this.isactive){
                urlStr = "/rzgs/stands?name="+this.searchTxt+"&Type="+this.isactive;
            }else {
                urlStr = "/rzgs/stands?name="+this.searchTxt;
            }
        }else if(!this.isactive && !this.searchTxt){
            urlStr = "/rzgs/stands";
        }
        axios({
            method: "get",
            url:
            process.env.VUE_APP_BASE_URL + urlStr
        }).then((response) => {
            if (response.data.status === 0) {
                this.standardList = response.data.data
            }
        });
    }
  },
  mounted() {
    if(localStorage.getItem('examineText')){
        this.searchTxt = localStorage.getItem('examineText')
    }
    this.arrList.forEach(item => {
        if(item.id == localStorage.getItem('examineId')){
            this.isactive = item.id;
        }
    })
    this.getdata();
  }
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 1200px;
  height: 100%;
  margin: 0 auto;
  .search {
    position: relative;
    width: 826px;
    margin: 32px auto 40px;
    height: 48px;
    line-height: 48px;
    span {
      display: inline-block;
      color: #fff;
      height: 48px;
      line-height: 48px;
      width: 105px;
      background-color: #4882F8;
      position: absolute;
      right: -2px;
      bottom: 0;
      letter-spacing: 6px;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
    }
  }
  .el-input {
    width: 100%;
    text-align: left;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
  }

  /deep/.el-input__inner {
    line-height: 48px;
    height: 48px;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #333;
  }
  .query-box{
    width: 1200px;
    margin:0px auto 96px auto;
  }
  .query-box>h3{
      width: 100%;
      font-size: 24px;
      font-weight: 500;
      color: #323233;
  }
  .query-box>h3>i{
      display: inline-block;
      width: 5px;
      height: 23px;
      background: linear-gradient(180deg, #74B7FF 0%, #4882F8 100%);
      opacity: 1;
      border-radius: 4px;
      vertical-align: middle;
      margin-right:8px;
  }
  .query-box .query-list{
      background: #fff;
      margin-top:24px;
      padding:0px 24px 32px 24px;
  }
  .outcome{
    margin:56px auto 26px auto;
    p{
      position: relative;
      width: 360px;
      border-bottom:1px solid #323233;
      margin:0 auto;
      span{
        position: absolute;
        top:50%;
        left:50%;
        margin-top:-10px;
        margin-left:-99px;
        width: 198px;
        background: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #323233;
      }
    }
  }
  .query-box .query-list .query-title{
      padding-bottom:13px;
      border-bottom:1px solid #DCDEE0;
      font-size: 0;
  }
  .query-box .query-list .query-title>span{
      display: inline-block;
      padding:13px 16px;
      margin-right:16px;
      margin-top:24px;
      font-size: 16px;
      font-weight: 400;
      color: #323233;
      border: 1px solid #DCDEE0;
      cursor: pointer;
  }
  .query-box .query-list .query-title>span>img{
      width: 18px;
      height: 18px;
      background-size: 100% 100%;
      vertical-align: middle;
  } 
  .bg{
      background: #DCDEE0;
      color:#4882F8 !important;
  }
  .query-box .query-list .query-bomb{
      width: 100%;
  }
  .query-bomb .query-result .result-title{
      margin-top:40px;
  }
  .query-bomb .query-result .result-title:nth-child(1){
      margin-top:32px;
  }
  .query-bomb .query-result .result-title>img{
      width: 28px;
      height: 28px;
      margin-right:7px;
      vertical-align: middle;
  }
  .query-bomb .query-result .result-title>span{
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      vertical-align: middle;
  }
  .query-bomb .query-result .result-text{
      width: 1152px;
      height: 116px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px rgba(200, 201, 204, 0.5);
      opacity: 1;
      margin-top:16px;
  }
  .query-bomb .query-result .result-text:hover{
      background-color: #F2F3F5;
      box-shadow: none;
  }
  .query-bomb .query-result .result-text>div{
      padding-top:24px;
  }
  .query-bomb .query-result .result-text>div>div{
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #969799;
      padding-left:16px;
  }
  .query-bomb .query-result .result-text>div>div>span{
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #323233;
  }
  .query-bomb .query-result .result-text>div>div .status{
      padding:2px 5px;
      text-align: center;
      border-radius: 2px;
      font-size: 12px !important;
      color:#fff;
  }
  .effective{
      background: #00B01B;
  }
  .suspended{
      background: #BFCA00;
  }
  .undo{
      background: #B00004;
  }
  .cancellation{
      background: #FF0000;
  }
  .overdue{
      background: #808080;
  }
  .query-result .result-text .result-info>div{
      width: 40%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .query-result .result-text .result-info>div:nth-child(2),
  .query-result .result-text .result-info>div:nth-child(3)
  {
      width: 21%;
  }
  .query-result .result-text .result-info .status-info{
      width: 12%;
  }
  .result-text .certificate-info{
      position: relative;
  }
  .query-result .result-text .certificate-info>div{
      width: 29%;
      overflow: hidden;
  }
  .result-text .certificate-info .unit-info{
      width: 62.3% !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
}
</style>
<style>
.el-tooltip__popper {
    max-width: 36%;
}
</style>